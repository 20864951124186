.imageGlow {
    -webkit-box-shadow: 0px -200px 300px -16px rgba(144, 137, 252, 1);
    -moz-box-shadow: 0px -200px 300px -16px rgba(144, 137, 252, 1);
    box-shadow: 0px -200px 300px -16px rgba(144, 137, 252, 1);
}

.imageGlow2 {
    -webkit-box-shadow: 0px -200px 300px -16px rgba(144, 137, 252, 1);
    -moz-box-shadow: 0px -200px 300px -16px rgba(144, 137, 252, 1);
    box-shadow: 0px 0px 100px -16px #312e81;
}

.perspective {
    perspective: 1000px;
  }
  
  .transform-style-preserve-3d {
    transform-style: preserve-3d;
  }
  
  .backface-hidden {
    backface-visibility: hidden;
  }
  
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
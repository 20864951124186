.App {
  text-align: center;
  scroll-behavior: smooth;
  
}

::-webkit-scrollbar {
  width: 0px; /* Ancho del scrollbar vertical */
  height: 12px; /* Altura del scrollbar horizontal */
}

::-webkit-scrollbar-track {
  background-color: black; /* Color del track (fondo del scrollbar) */
}

::-webkit-scrollbar-thumb {
  background-color: black; /* Color del thumb (la parte deslizable del scrollbar) */
  border-radius: 6px; /* Borde redondeado del thumb */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

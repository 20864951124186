@keyframes moveGlow {
    0%, 100% {
        transform: translateX(-50%);
    }
    50% {
        transform: translateX(50%);
    }
}


#glowcircle{
    top: 60%;
    left: 25%;
    display: none;
}

/* Para dispositivos móviles (Celulares) */
@media (max-width: 575.98px) {
    #glowcircle{
        top: 40%;
        left: 50%;
    }
  }
  
  /* Para tabletas */
  @media (min-width: 576px) and (max-width: 991.98px) {
    #glowcircle{
        top: 40%;
        left: 50%;
        width: 95%;
    }
  }
  
  /* Para pantallas de escritorio (PS) */
  @media (min-width: 992px) {
    #glowcircle{
        top: 60%;
        left: 25%;
        display: none;
    }
  }



  .rojo{
    border: solid red 1px;
  }

  .azul{
    border: solid blue 1px;
  }

  